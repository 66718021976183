.color-change-2x {
  -webkit-animation: color-change-2x 8s ease-in-out infinite alternate both;
  animation: color-change-2x 8s ease-in-out infinite alternate both;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #1996ea;
  }

  100% {
    background: #b22cff;
  }
}

@keyframes color-change-2x {
  0% {
    background: #1996ea;
  }

  100% {
    background: #9e32a1b2;
  }
}


.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
